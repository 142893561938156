import React from 'react'

const NotFoundPage = () => (
  <article className="article">
    <h1>Page Not Found</h1>
    <p>You just hit a page that doesn&#39;t exist. I’d like to recommend <a href="/posts/more-chaos" className="link">More Chaos</a> and <a href="/posts/less-action" className="link">Less Action</a></p>


  </article>
)

export default NotFoundPage
